import React from "react";
import {
  useNavigate
} from "react-router-dom";
import translation from "./translation";

const Header = ({ openHeader, englishMode, setOpenHeader, scrollToAccess, scrollToTop, pathname }) => {
  const navigate = useNavigate();
  const navigateOrToTop = (path, switchLanguage) => {
    setOpenHeader(false);
    scrollToTop()

    if(switchLanguage) {
      navigate(`${englishMode ? '' : '/en' }/${path}`)
    } else  if(!path.includes(pathname)) {
      navigate(`${englishMode ? '/en' : '' }/${path}`)
    }
  }

  const isActive = (currentPathname, path) => {
    if(path === '') { return currentPathname === '/' || currentPathname === '/en/' }
    return currentPathname.includes(path)
  }

  return (<header className={`header ${openHeader ? 'header--open': ''}`}>
    <button className="header__button" onClick={() => setOpenHeader(true)}>
      <span></span><span></span><span></span>
    </button>
    <div className="header__mobile-screen">
      <button className="header__mobile-screen__button" onClick={() => setOpenHeader(false)}>
        <span></span><span></span>
      </button>
      <div className='container'>
        <ul>
          <li className={`${isActive(pathname, '') ? 'active' : ''}`}>
            <span>{ translation('一', englishMode) }</span>
            <a onClick={() => { 
              navigateOrToTop('')
            }}>{ translation('ホーム', englishMode) }</a>
          </li>
          <li className={`${isActive(pathname, 'gallery') ? 'active' : ''}`}>
            <span>{ translation('二', englishMode) }</span>
            <a onClick={() => { 
              navigateOrToTop('gallery') 
            }}>{ translation('ギャラリー', englishMode) }</a>
          </li>
          <li className={`${isActive(pathname, 'food') ? 'active' : ''}`}>
            <span>{ translation('三', englishMode) }</span>
            <a onClick={() => { 
              navigateOrToTop('food') 
            }}>{ translation('お料理', englishMode) }</a>
          </li>
          <li className={`${isActive(pathname, 'chef') ? 'active' : ''}`}>
            <span>{ translation('四', englishMode) }</span>
            <a onClick={() => { 
              navigateOrToTop('chef') 
            }}>{ translation('職人', englishMode, 'header') }</a>
          </li>
          <li>
            <span>{ translation('五', englishMode) }</span>
            <a target="_blank" href={ englishMode ? 'https://www.tablecheck.com/en/shops/yano-kyoto/reserve' : 'https://www.tablecheck.com/shops/yano-kyoto/reserve'}>
              { translation('ご予約', englishMode) }
            </a>
          </li>
          <li>
            <span>{ translation('六', englishMode) }</span>
            <a onClick={() => { 
              scrollToAccess();
            }}>{ translation('店舗案内', englishMode, 'header') }</a>
          </li>
          {
            <li className="language">
              <a onClick={() => { 
              navigateOrToTop('', true);
              }}>
            { englishMode ? '日本語' : 'English' }
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 10L14 7L11 4" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1 1V3C1 4.06087 1.42143 5.07828 2.17157 5.82843C2.92172 6.57857 3.93913 7 5 7H14" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
               </a>
            </li>
          }
        </ul>
      </div>
      <img className="header__mobile-screen__logo" src="/images/logo.png" />
      <div className="header__mobile-screen__address">
        {
          englishMode ? <>
            <p>
              436 Uoya-cho, Nakagyo-ku,<br />
              Kyoto-shi, Kyoto, 604-8082 Japan
            </p>
            <p>
              <a href='tel:+810757446786'>+81-75-744-6786</a>
            </p>
          </> :
          <>
            <p>
              日本料理　矢の
            </p>
            <p>
              京都府京都市中京区魚屋町436<br></br>
              <a href='tel:+810757446786'>075-744-6786</a>
            </p>
          </>
        }

      </div>
    </div>
  </header>)
}

export default Header;