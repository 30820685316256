export default (text, englishMode, context) => {
  if(!englishMode) { return text;}
  switch(text) {
    case '覗く':
      return 'Peek into the essence of Japanese cuisine.';
    case 'お料理':
      return 'Dishes';
    case 'お料理について':
      return 'About our cuisine.';
    case '職人':
      if(context === 'header') {
        return 'Chef';
      }
      return 'Craftsmanship in every dish.';
    case '職人について':
      return 'About our chef.';
    case '店舗案内':
      if(context === 'header') {
        return 'Access';
      }
      return 'Yano';
    case 'ご予約':
      return 'Reservation';
    case '料理長':
      return 'Lead Chef';
    case '矢野　由彦':
      return 'Yoshihiko Yano';
    case '一':
      return '1'
    case 'ホーム':
      return 'Home'
    case '二':
      return '2'
    case 'ギャラリー':
      return 'Gallery'
    case '三':
      return '3'
    case '四':
      return '4'
    case '五':
      return '5'
    case '六':
      return '6'
    default:
      return text;
  }
}