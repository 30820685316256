import './Gallery.css';

import React from "react";
import ParallaxPage from '../../ParallaxPage';
import Footer from '../../footer';

export default ({ setFullpageApi, englishMode }) => (<ParallaxPage setFullpageApi={setFullpageApi} pageClassName="gallery">
  <section className="gallery__banner section">
    <div className="fp-bg"></div>
    <div className="container">
      <div className="gallery__banner__text section__text">
        {
          englishMode ? <>
          <p>We are located in Goshominami, an area popularly known as the southern part of the Kyoto Imperial Palace, where citizens love to visit.</p>
          <p>The area is known for its calm atmosphere, even with its wide range of dining options ranging from traditional Kyoto cuisine to trendy cafes.We are certified as a Kyoto machiya, a traditional Kyoto-style townhouse. </p>
          <p>Our building has a modern design and serves as an adult hideaway.</p>
          </> : <>
            <p>京都御所を有する京都御苑の南側、通称 御所南 として市民の方に親しまれている場所にございます。</p>
            <p>古くから京都に根付くお店から新進気鋭のカフェまで、幅広く飲食店が立ち並びながらも、落ち着いた雰囲気に包まれています。</p>
            <p>近辺では唯一京町家の認定を受けており、近代的な建物の中に佇む大人の隠れ家になっております。</p>
          </>
        }

      </div>
    </div>
  </section>
  <section className="gallery__second section">
    <div className="fp-bg"></div>
    <div className="container">
      <div className="gallery__second__text section__text">
        {
          englishMode ? <>
          <p>Our establishment's appearance is characterized by a grid and a "komayose," a unique architectural feature of Japanese houses with a latticed wooden grill, situated in a quiet Kyoto machiya.</p>
          <p>As you slide open our small wooden door, you will be greeted by an entrance that still bears the design of the building's original layout.</p>
          <p>As you walk on the cobblestone pathway and pass through the noren (Japanese-style curtain), you will be surrounded by the design of Akira Sugihara, a designer who has been involved in many renowned restaurants in Kyoto.</p>
          </> : <>
            <p>仕舞屋の造りに、格子と駒寄せが目印のひっそりとした京町家の佇まいのお店です。</p>
            <p>小さな片引き戸を引くと、建築当時の意匠を残した玄関がお客さまをお出迎えいたします。</p>
            <p>石畳を歩き、暖簾をくぐると、京都の名店を数多く手がけたデザイナー 杉原明氏の意匠が広がります。</p>
          </>
        }
      </div>
    </div>
  </section>
  <section className="gallery__bowl section">
    <div className="fp-bg"></div>
    <div className="container">
      <div className="section__text section__text">
        {
          englishMode ? <>
          <p>
            The cutlery and tableware we employ is carefully selected to fit with the season and our dishes.
          </p><p>
            You will be able to how the cuisine harmonizes with the beauty of our tableware - all carefully selected from various artisans.
          </p>
          </> : <>
          <p>
            季節やお料理に合わせた器や設えをご用意しております。
          </p><p>
            様々な作家の器を集め、美しい器とお料理との相性も楽しんでいただけます。
          </p>
          </>
        }
      </div>
    </div>
  </section>
  <section className="gallery__food section">
    <div className="fp-bg"></div>
    <div className="container">
      <div className="section__text section__text season-text">
        {
          englishMode ? <>
          <p>Our specialty is in our dishes that capture the essence of the seasons: the first taste, the peak, and the last remaining flavors.</p>
          <p>Spring: Kyoto Nishiyama Takenoko (bamboo shoots), Tango abalone, and more</p>
          <p>Summer: Kamo eggplant, Ayu (sweetfish) caught by a friend, Biwamasu, a species endemic to Lake Biwa, and more</p>
          <p>Autumn: Domestic matsutake mushrooms, Tanba chestnuts, male and female Matsuba crabs, Amadai (tilefish), and more</p>
          <p>Winter: Seigoin turnips, Shrimp taro, Matsuba crab, and natural fugu (pufferfish) caught in the nearby sea, and more</p>
          <p>Year-round: Kyoto meat, Matsusaka beef, and Kobe beefIf you have any requests regarding the ingredients or dishes, we will do our best to prepare the dishes according to your preferences.</p>
          </> : <>
          <p>
            走り、旬、名残りを軸とし、時をとらえたお料理が自慢です。
          </p>
          <p className="season">春）京都西山白子筍　丹後の鮑など</p>
          <p className="season">夏）加茂茄子　友釣天然鮎　琵琶湖固有種ビワマスなど</p>
          <p className="season">秋）国産松茸　丹波栗　松葉蟹雄雌　甘鯛など</p>
          <p className="season">冬）聖護院蕪　海老芋　松葉蟹　近海産天然ふぐなど　</p>
          <p className="season">通年）京都肉　松坂牛　神戸牛　
          </p>
          <p>
            食材やお料理のリクエストがございましたら出来る限りご要望に合わせたお料理をご用意いたします。
          </p>
          </>
        }
      </div>
    </div>
  </section>
  {
    [...Array(4).keys()].map(() => <section className="section">
      <div className='fp-bg' />
    </section>)
  }
  <Footer englishMode={englishMode} />
</ParallaxPage>)
