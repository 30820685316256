import { useState, useEffect } from 'react';
import './normalize.css';
import './app.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  // useSearchParams
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import Header from './header';

import Home from './pages/Home';
import Gallery from './pages/Gallery';
import Food from './pages/Food';
import Chef from './pages/Chef';
// import TableCheck from './components/tablecheck';

const AnimatedPage = ({ children, blurMode }) => <motion.div
  initial={{
    opacity: 0,
    transform: blurMode ? null : "translateX(-25%)",
    filter: blurMode ? "blur(15px)" : null,
  }}
  animate={{
    opacity: 1,
    transform: blurMode ? 'translateX(0)' : "0",
    filter: blurMode ? "blur(0px)" : null,
  }}
  onAnimationStart={() => {
    document.body.classList.add('animating');
    if(document.fullpageApi) {
      document.shouldStopAnimation = true
      document.fullpageApi.setAllowScrolling(false);
    }
  }}
  onAnimationComplete={() => { 
    if(document.fullpageApi) {
      document.shouldStopAnimation = false
      document.fullpageApi.setAllowScrolling(true);
      document.fullpageApi.silentMoveTo(2)
      document.fullpageApi.silentMoveTo(1)
    }
    document.body.classList.remove('animating');
  }}
  exit={{
    opacity: 0,
    transform: "translateX(25%)",
    filter:  blurMode ? "blur(15px)" : null,
    transition: blurMode ? {
      ease: "easeIn",
      delay: 0,
      duration: 0.8
    } : undefined
  }}
  transition={{
    ease: "easeIn",
    duration: blurMode ? 1.5 : 0.8,
    delay: blurMode ? 2 : 0,
  }}
>
  {children}
</motion.div>

export const LogoLoader = () => <motion.div
  className='logoLoader logo-container'
  style={{
    position: "fixed",
    inset: 0,
    width: "120vw",
    height: "150vh",
    left: "-10vw",
    top: "-25vh",
  }}
  initial={{
    background: "#3a1f04",
    zIndex: 99,
  }}
  animate={{
    background: "rgba(58,31,4,0)",
    transitionEnd: {
      zIndex: -1,
      pointerEvents: "none",
    }
  }}
  transition={{
    delay: 2,
    duration: 1.5
  }}
>
  <motion.img
    src="/images/logo.png"
    initial={{
      opacity: 0,
      filter: 'blur(0px)'
    }}
    animate={{
      opacity: [0,1,0],
      filter: ['blur(0px)', 'blur(0px)', 'blur(40px)'],
      transition: {
        times: [0, 0.5, 1],
        duration: 2,
      }
    }}
  />
</motion.div>
  
const Content = () => {
  const location = useLocation();
  const { pathname } = location;
  
  const [ openHeader, setOpenHeader ] = useState(false);
  const [ fullpageApi, setFullpageApi ] = useState(undefined);
  const [ englishMode, setEnglishMode ] = useState(false);

  const scrollToAccess = () => {
    const hasFullPage = !!document.querySelector('#fullPage')
    if(document.fullpageApi && hasFullPage) {
      setOpenHeader(false);
      const accessSectionIndex = document.querySelectorAll('.fp-section').length - 1
      document.fullpageApi.moveTo(accessSectionIndex);
    } else {
      document.querySelector('#access-point').scrollIntoView()
    }
  }

  const scrollToTop = () => {
    const hasFullPage = !!document.querySelector('#fullPage')
    if(document.fullpageApi && hasFullPage) {
      document.fullpageApi.moveTo(1);
    } else {
      window.scrollTo(0,0);
    }
  }

  useEffect(() => {
    setEnglishMode(pathname.includes("/en/"));
  },[pathname]);

  return (<div className={`App ${englishMode ? 'en' : 'ja'}`}>
    <Header pathname={pathname} englishMode={englishMode} openHeader={openHeader} setOpenHeader={setOpenHeader} scrollToAccess={scrollToAccess} scrollToTop={scrollToTop} />
    <AnimatePresence mode="wait">
      {/* {
        pathname.includes('/chef') && <LogoLoader />
      } */}
      <Routes location={location} key={pathname}>
        {
          [
            { elem: <Gallery setFullpageApi={setFullpageApi} />, path: "/gallery"},
            { elem: <Food setFullpageApi={setFullpageApi} />, path: "/food"},
            { elem: <Chef />, path: "/chef"},
            { elem: <Home setFullpageApi={setFullpageApi} />, path: "/"},
            { elem: <Gallery englishMode={true} setFullpageApi={setFullpageApi} />, path: "/en/gallery"},
            { elem: <Food englishMode={true} setFullpageApi={setFullpageApi} />, path: "/en/food"},
            { elem: <Chef englishMode={true} />, path: "/en/chef"},
            { elem: <Home englishMode={true} setFullpageApi={setFullpageApi} />, path: "/en/"}
          ].map(data => <Route element={
            <AnimatedPage fullpageApi={fullpageApi} blurMode={false}>{data.elem}</AnimatedPage>
          } key={data.path} path={data.path} />)
        }
      </Routes>
    </AnimatePresence>
  </div>)
}

function App() {
  return (
    <Router>
      <Content />
    </Router>
  );
}

export default App;
