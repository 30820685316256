import './Home.css';

import React from "react";
import { Link } from "react-router-dom";
import ParallaxPage from '../../ParallaxPage';
import Footer from '../../footer';

import translation from '../../translation';

export default ({ setFullpageApi, englishMode }) => {
  return (
    <ParallaxPage setFullpageApi={setFullpageApi} pageClassName="top">
      <section key='topBanner' className="section top__banner">
        <div className="fp-bg"></div>
        <div className="container logo-container">
          <img src="/images/logo.png" />
        </div>
      </section>
      <section key='topGallery' className="section top__gallery">
        <div className="fp-bg"></div>
        <div className="container">
          <div className="section__text">
            {
              englishMode ? <>
                <p>Experience the 24 seasonal changes, savor the flavors of Japan.</p>
                <p>Enhance your special moments with your loved ones in our century-old Kyoto machiya (traditional wooden townhouse).</p>
              </>: <>
                <p>二十四節気を味わう、日本を味わう。</p>
                <p>築百余年の京町家で、大切な人との特別なひとときを彩る。</p>
                <p>矢のだけの、心よりくつろげる隠れ家的空間を創出。</p>
                <p>料理を通じて、人と人との特別なつながりをつくりだす。</p>
              </>
            }

            <Link to={`/${englishMode ? 'en/' : ''}gallery`} className="style-link">
              { translation('覗く', englishMode) }
            </Link>
          </div>
        </div>
      </section>
      <section key='topFood' className="section top__food">
        <div className="fp-bg"></div>
        <div className="container">
          <div className="section__text">
            <h2>{ translation('お料理', englishMode) }</h2>
            {
              englishMode ? <>
                <p>Our kaiseki (traditional multi-course) cuisine adheres to the fundamentals of Japanese cuisine, while enabling you to feel the changing seasons and the breeze of Kyoto.</p>
                <p>We source fresh ingredients from Kyoto and other parts of Japan, and offer a multisensory culinary experience.</p>
              </> : <>
                <p>
                京都の食文化や伝統を紡ぎ、未来へ続く文化を創造。
                </p>
                <p>
                  季節の食材を厳選し、素材の味を余すことなく引き出した 特別な食体験を提供。
                </p>
              </>
            }
            <Link to={`/${englishMode ? 'en/' : ''}food`} className="style-link">
            { translation('お料理について', englishMode) }
            </Link>
          </div>
        </div>
      </section>
      <section key='topChef' className="section top__chef">
        <div className="fp-bg"></div>
        <div className="container">
          <div className="section__text">
            <h2>{ translation('職人', englishMode) }</h2>
            {
              englishMode ? <>
              <p>We honor the changing seasons and the local culture and have been devoted to Japanese cuisine for more than 30 years.</p>
              <p>Leveraging over 20 years of experience as a head chef, we deliver the best cuisine of the day to our guests.</p>
              </> : <>
              <p>
              時候や風土を大切に、真摯に日本料理と向き合い三十年余。
              </p>
              <p>
              二十年間の料理長としての経験を活かし、その日最高のお料理をお届けいたします。
              </p>
              </>
            }
            
            <Link to={`/${englishMode ? 'en/' : ''}chef`} className="style-link">
              { translation('職人について', englishMode) }
            </Link>
          </div>
        </div>
      </section>
      <Footer englishMode={englishMode} />
    </ParallaxPage>
  )
}
