import React from "react";

import Footer from "../../footer";
import translation from "../../translation";
import './Chef.css';

export default ({ englishMode }) => (<main className="chef">
  <section className="section__decoration tablet-and-above">
    <img src='/images/home/home-4.jpg' />
  </section>
  <section className="chef__intro">
    <div className="container">
      <div className="chef__intro__image">
        <img className="tablet-and-above" src='/images/chef/chef-pc.jpg' />
        <img className="sp-only" src='/images/home/home-4.jpg' />
      </div>
      <h1>
        <small>{ translation('料理長', englishMode) }</small>
        <span>{ translation('矢野　由彦', englishMode) }</span>
      </h1>
      <div className="chef__intro__profile">
        {
          englishMode ? <>
            <p>Born in Nobeoka, Miyazaki Prefecture in 1969.</p>
            <p>I entered the culinary world in my teens and began my training in Yamaguchi Prefecture. I moved to Kyoto at the age of 20 and continued my apprenticeship in Gion.</p>
            <p>I was appointed as the head chef at Gioan Hatano when I was 32 years old and served as the head chef of Ishibekoji Kamikura at the age of 42.</p>
            <p>I have been the owner of Yano since I was 53 years old.</p>
          </> : <>
            <p>
            宮崎県延岡市生まれ
            </p><p>
            十代より料理の世界に入り、山口県より修行を始める。
            </p><p>
            二十歳の時に京都へ。
            以後祇園界隈で修行に励む。
            </p><p>
            三十二歳よりぎおん畑中料理長に
            </p><p>
            四十二歳より石堀小路かみくら料理長兼務
            </p><p>
            五十三歳より日本料理矢の店主
            </p>
          </>
        }
      </div>
      <img className="sp-only" src={`/images/chef/chef-image-1.jpg`} />
      <div className="chef__intro__profile-two">
        {
          englishMode ? <>
          <p>I was born in Nobeoka, a city surrounded by magnificent nature. Growing up near the sea, mountains, and rivers, I enjoyed fishing and free-diving. I learned to cook by grilling the fish I caught from those rivers, fostering my passion for preparing meals myself and aspiring to become a chef.</p>
          <p>Without losing sight of that aspiration, I have dedicated over 30 years to honing my skills as a chef. I will continue to embrace my lifelong commitment as a cook and strive to provide dishes that leave a lasting impression in the hearts of our customers.</p>
          </> : <>
            <p>
              雄大な自然の広がる延岡に生まれ、自宅近くの海山川で遊び、釣りや素潜りをして育ちました。その川で採れた魚を自分で焼いて食べるなど、自ら調理することを覚え、料理人を夢見るようになりました。
            </p>
            <p>
              その志を失わず、これまで30年余り一料理人として研鑽してまいりました。
            </p>
            <p>
              これからも生涯料理人を貫き通し、お客さまの心に残るお料理を提供できるよう、日々精進してまいります。
            </p>
          </>
        }
      </div>
    </div>
  </section>
  <section className="chef__gallery">
    <div className="container">
      {
        [...Array(3).keys()].map(number => <img src={`/images/chef/chef-image-${number + 1}.jpg`} />)
      }
    </div>
  </section>
  <Footer englishMode={englishMode} />
</main>)
