import React, { useEffect } from "react";
import ReactFullpage from '@fullpage/react-fullpage';

const pluginWrapper = () => {
  require('./js/fullpage.parallax.min');
};

export default ({ pageClassName, setFullpageApi, children }) => {
  let fullpageApiHolder;
  useEffect(() => {
    if(fullpageApiHolder) {
      setFullpageApi(fullpageApiHolder)
    }
  })
  return (<ReactFullpage
    pluginWrapper={pluginWrapper}
    parallax={true}
    autoScrolling={true}
    scrollingSpeed={1250}
    licenseKey='L3W7H-WMA0I-1H887-ON79H-AMYMP'
    parallaxKey='ZE1hM2x2ZEc4dGVXRnVieTVxY0E9PUdiXzE5WWNHRnlZV3hzWVhnPTZwag=='
    { ...(window.innerWidth < 768 && pageClassName === 'food' ? {
      beforeLeave: (_, destination) => { // eslint-disable-line no-unused-vars
        switch (destination.index) {
          case 0:
          case 2:
          case 3:
          case 4:
            document.fullpageApi.setScrollingSpeed(900);
            return;
          default:
            document.fullpageApi.setScrollingSpeed(1250);
            break  
        }
      },
    } : {}) }

    render={({ fullpageApi }) => {
      if(fullpageApi) {
        document.fullpageApi = fullpageApi

        if(document.shouldStopAnimation) {
          fullpageApi.setAllowScrolling(false);
        }
      }
      return (<main className={pageClassName} id="fullPage">
        <ReactFullpage.Wrapper>
          { children }
      </ReactFullpage.Wrapper>
    </main>)
    }}
  />)
}
