import React from "react";
import './Food.css';
import Slider from "react-slick";
import ParallaxPage from "../../ParallaxPage";
import Footer from "../../footer";

const slickerSettings = {
  infinite: true,
  variableWidth: true,
  centerMode: true,
  slickPrev: null,
  slickNext: null,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 2000,
}

export default ({ setFullpageApi, englishMode }) => (<ParallaxPage setFullpageApi={setFullpageApi} pageClassName="food">
  <section className="section section__decoration">
    <div className="fp-bg"></div>
  </section>
  <section className="section sandpaper-text">
    <div className="container">
      <div className="sandpaper-text__text">
        {
          englishMode ? <>
            <p>At Yano, we emphasize the importance of water in Japanese cuisine.</p>
            <p>The pristine nature of Kyoto, often described as 'mountains, purple haze, clear waters,' has nurtured a rich culture.</p>
            <p>Underneath Kyoto city lies an extensive underground water reservoir that rivals Lake Biwa, allowing us to draw high-quality water.</p>
            <p>In Kyoto's culinary tradition, which includes tofu, Yuba (tofu skin), and sake, abundant underground water is indispensable.</p>
            <p>Even in the vicinity of our restaurant, there are scattered sources of Kyoto's renowned groundwater, such as the Somenoi water at Nashinoki Shrine and the Goryosui water at Shimotsumichi Shrine.</p>
            <p>Motivated by this background, the owner of Yano wondered if it was possible to offer the hidden water beneath our establishment to our guests. Through an investigation of the garden, we discovered a source of exceptional water.</p>
            <p>The underground water we draw at our restaurant is used in the preparation of our dishes.</p>
          </> :
          <>
            <p>日本料理 矢のは水にこだわります</p>
            <p>山紫水明と形容される京都の自然は、豊かな文化をはぐくんできました。</p>
            <p>京都市内の地下には琵琶湖に匹敵する量の地下水が貯留されており、良質なお水を汲み上げることができます。</p>
            <p>豆腐、湯葉や日本酒をはじめとする京都の食において、豊富な地下水はなくてはならないものです。</p>
            <p>当店の近隣にも、梨木神社の染井の水、下御霊神社の御霊水といった京都の名水と呼ばれる地下水が点在しています。</p>
            <p>その背景から店主は、矢のの地下に眠る水をお客さまにお出しできないかと考え、坪庭を調査したところ、名水に出会うことができました。</p>
            <p>当店で汲み上げた地下水は、お出しする料理に使われています。</p>
            <p>京都の歴史と歩んできた地下水を、料理と共にお楽しみください。</p>
          </>
        }

      </div>
    </div>
  </section>
  <section className="section section__decoration">
    <img src="/images/food.jpg"/>
  </section>

  <section className="section food__slider">
    <div className="food__slider__container">
      <Slider {...slickerSettings}>
        {
          [...Array(6).keys()].map(key => key + 1).map(key => <div>
            <img src={`/images/food/slider/0${key}.jpg`} />
          </div>)
        }
      </Slider>
    </div>
  </section>

  <section className="section section__decoration">
    <img src="/images/food/assortment.jpg"/>
  </section>
  <Footer englishMode={englishMode} />
</ParallaxPage>)
